import { Form, Input, Button, Switch } from "antd";
import React, { useContext, useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { AuthContext } from "../../context/GlobalContextProvider";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

export default function Login() {
  const context = useContext(AuthContext);
  const [checked, setChecked] = useState(true);

  const onFinish = async values => {
    await context.handleLogin(values, checked);
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout image="">
      <SEO keywords={[`Bivver`, `Construção`]} title="Login" />
      <Form
        className="self-center"
        layout="vertical"
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Digite o usuário!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Usuário"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Digite a senha!" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Senha"
          />
        </Form.Item>
        <Switch
          size="small"
          checked={checked}
          style={{ backgroundColor: checked ? "orange" : "" }}
          onChange={() => (checked ? setChecked(false) : setChecked(true))}
          className="mr-3"
        />
        <span className="font-semibold">Lembrar de mim</span>
        <Form.Item>
          <Button
            style={{ width: "100%", color: "white", backgroundColor: "orange" }}
            htmlType="submit"
            className="mt-5"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
}
